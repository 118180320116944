import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import FAQs from "../components/FAQs";

export const FAQPageTemplate = ({ title, faqs }) => {
    // const PageContent = contentComponent || Content

    return (
        <section className="section section--gradient has-juliet-image">
            <div className="container">
                <div className="columns">
                    <div className="column is-10 is-offset-1">
                        <div className="section">
                            <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                                {title}
                            </h2>
                            <FAQs faqs={faqs} />
                            <Link to="/contact">Get in touch</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

FAQPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func
};

const FAQPage = ({ data }) => {
    const { markdownRemark: post } = data;

    return (
        <Layout>
            <FAQPageTemplate
                title={post.frontmatter.title}
                faqs={post.frontmatter.faqs}
            />
        </Layout>
    );
};

FAQPage.propTypes = {
    data: PropTypes.object.isRequired
};

export default FAQPage;

export const faqPageQuery = graphql`
    query FAQPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                faqs {
                    answer
                    question
                }
            }
        }
    }
`;
